import React, { useState, Component } from 'react'
import './NavBar.css';
import  logo from './logo.svg';
import  sitemapArrow from './sitemap-arrow.svg';
import { useNavigate } from 'react-router-dom'



export default function NavBar({scrollDirection}) {
  let navigate = useNavigate()
  const [isHovering, setIsHovering] = useState(false);
  const [OnClick , setOnClick] = useState(false);
  const [checkValue, setCheckValue] = useState('');
  const [toggleItme,setToggleItem] = useState(false);
  const [navbarList, setNavbarList] = useState([
    {
      id:1,
      className:'sitemap-group1',
      title:'회사소개', 
      sub1:'기업정보', sub2:'채용정보', sub3:'제이소프트랩',
      subLink1:'/CompanyIntro', subLink2: '/Recruit', subLink3:'/jsoftlab'
    },
    {
      id:2,
      className:'sitemap-group2',
      title:'사업분야',
      sub1:'IPT시스템 컨설팅', sub2:'Network 구축컨설팅', sub3:'WLAN 구축컨설팅', sub4:'유지보수',sub5:'총판사업',
      subLink1:'IptConsulting', subLink2:'/NetworkBuilding', subLink3: '/WlanBuilding', subLink4: '/Maintenance', subLink5:'/Distributor'
    },
    {
      id:3,
      className:'sitemap-group3',
      title:'제품정보',
      sub1:'IPT', sub2:'NETWORK', sub3:'WLAN', sub4:'보안', //sub5:'R&D',
      subLink1: '/Ipt', subLink2:'/Network', subLink3:'/Wlan', subLink4: '/Security',//subLink5:'/Software'
    },
    {
      id:4,
      className:'sitemap-group4',
      title:'고객문의',
      sub1:'견적문의', sub2:'오시는길',
      subLink1:'/QuoteInquiry', subLink2: '/Directions'
    },
  ])

  const handleOnClick = () => { 
    setOnClick(!OnClick);
  } 
  const handleMouseOver = () => {
    setIsHovering(true);
  }
  const handleMouseOut = () => {
    setIsHovering(false);
  }
  const checkOnlyOne = (e) => {
    setCheckValue(e.target.value);
    let checkItem = document.getElementsByName('useType');
    Array.prototype.forEach.call(checkItem, function (el){
      el.checked = false;
    });
    e.target.checked = true;
  }

  return (
    <header id="header" className={`header ${isHovering ? "active" : "default"} ${scrollDirection.a === "down" ? "hide" : ""}`} >
        <div className={ `${OnClick ? "header-wrap open" : "header-wrap"} ${scrollDirection.b === "hide" || scrollDirection.b === null ? "" : "header-bg" }`}>
          <h1>
            <a href="/">
              <img src={logo} className='logo'></img>
            </a>
          </h1>
          <nav className="gnb" >
            <ul onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <li>
                <h2 className="header-list"><a href="/CompanyIntro" >회사소개</a></h2>
                <div className="gnb-wrap">
                  <div>
                    <p className="gnb-title">ABOUT US</p>
                    <ul>
                      <li><a href="/CompanyIntro">기업정보</a></li>
                      <li><a href="/Recruit">채용정보</a></li>
                      <li><a href="/jsoftlab" >제이소프트랩</a></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <h2 className="header-list"><a href="/IptConsulting">사업분야</a></h2>
                <div className="gnb-wrap">
                  <div>
                    <p className="gnb-title">BUSINESS</p>
                    <ul>
                      <li><a href="/IptConsulting">IPT시스템 컨설팅</a></li>
                      <li><a href="/NetworkBuilding">Network 구축컨설팅</a></li>
                      <li><a href="/WlanBuilding">WLAN 구축컨설팅</a></li>
                      <li><a href="/Maintenance">유지보수</a></li>
                      <li><a href="/Distributor">총판사업</a></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <h2 className="header-list"><a href="/Ipt">제품정보</a></h2>
                <div className="gnb-wrap">
                  <div>
                    <p className="gnb-title">PRODUCT</p>
                    <ul>
                      <li><a href="/Ipt">IPT</a></li>
                      <li><a href="/Network">NETWORK</a></li>
                      <li><a href="/Wlan">WLAN</a></li>
                      <li><a href="/Security">보안</a></li>
                      {/* <li><a href="/Software">R&D</a></li> */}
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <h2 className="header-list"><a href="/QuoteInquiry">고객문의</a></h2>
                <div className="gnb-wrap">
                  <div>
                    <p className="gnb-title">SUPPORT</p>
                    <ul>
                      <li><a href="/QuoteInquiry">견적문의</a></li>
                      <li><a href="/Directions">오시는길</a></li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </nav>

          <div className="snb">
            <button className={OnClick ? "nav-icon active"  : "nav-icon"} onClick={()=>handleOnClick()}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>

        <div className={OnClick ? "sitemap show" : "sitemap"} >
          <div className="sitemap-wrap">
            <div className="sitemap-header"></div>
            <ul className="sitemap-gnb">
            {
              navbarList.map((item,index) => {
                return (
                  <li className={item.className} key={item.title}>
                    <input
                    style={{display:'none'}}
                    type='radio'
                    id={item.id}
                    name='useType'
                    value={item.title}
                    onChange={(e)=> checkOnlyOne(e)}
                    checked={checkValue == item.title}
                    >
                    </input>
                    <label className='navbar-title' htmlFor={item.id} 
                    onClick={()=>setToggleItem(false)}
                    >
                      <span>{item.title}</span>
                      <span className={ checkValue == item.title ? 'arrow active' : 'arrow' }><img src={sitemapArrow}></img></span>
                    </label>
                    <ul className={checkValue == item.title ? "sitemap-list" : "sitemap-list close"}>
                      <li><a href={item.subLink1}>{item.sub1}</a></li>
                      <li><a href={item.subLink2}>{item.sub2}</a></li>
                      <li><a href={item.subLink3}>{item.sub3}</a></li>
                      <li><a href={item.subLink4}>{item.sub4}</a></li>
                      <li><a href={item.subLink5}>{item.sub5}</a></li>
                    </ul>
                  </li>
                )
              })
            }
            </ul>
          </div>
        </div>
    </header>
  )
}
